<template>
  <div class="templateClass">
    <div class="templateCentreContent">
      <el-card class="box-card">
        <div slot="header" style="position:relative">
          <div class="flex query">
            <el-input
              style="width: 320px;"
              class="input-field"
              clearable
              placeholder="请输入角色名称"
              v-model="queryData.like[0][2]"
              @keyup.enter.native="getDataList(true)"
            ></el-input>

            <el-button type="success" class="ml10" @click="getDataList(true)">查询</el-button>

            <el-button class="op_btn" type="primary" @click="addOrEditPopup()">新增角色</el-button>
          </div>
        </div>

        <!-- PC端展示 -->
        <el-table
          class="pc-table"
          :data="listData"
          v-loading="loading"
          element-loading-text="正在拼命加载中"
          element-loading-spinner="el-icon-loading"
          border
          stripe
        >
          <el-table-column type="index" :index="1" label="#" width="50"></el-table-column>
          <el-table-column prop="roleName" label="角色名称" min-width="120"></el-table-column>
          <el-table-column prop="indexUrl" label="入口页" min-width="120"></el-table-column>
          <el-table-column prop="remark" label="备注" min-width="200"></el-table-column>
          <el-table-column prop="sort" label="排序" min-width="50"></el-table-column>
          <el-table-column label="创建时间" min-width="160">
            <template slot-scope="scope">
              <p>{{ scope.row.createTime | dateFormat1 }}</p>
            </template>
          </el-table-column>
          <el-table-column fixed="right" prop="operation" label="操作" width="200px;">
            <template slot-scope="scope">
              <div class="tableOperation">
                <el-button type="text" @click="addOrEditPopup(scope.row.roleId)">编辑</el-button>
                <span class="operationLine">|</span>
                <el-button class="F56C6C" type="text" @click="del(scope.row.roleId)">删除</el-button>
                <span class="operationLine">|</span>
                <el-button type="text" @click="toGrantAuthorizationPopup(scope.row.roleId)">授权</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>

       <!-- 移动端展示 -->
       <div class="mobile-cards">
          <el-card
            v-for="(item, index) in listData"
            :key="index"
            class="role-card"
            shadow="hover"
          >
            <div class="card-content">
              <div class="card-header">
                <!-- <el-dropdown trigger="click">
                  <el-button type="text" class="el-dropdown-link">
                    操作<i class="el-icon-arrow-down el-icon--right"></i>
                  </el-button>
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="addOrEditPopup(item.roleId)">编辑</el-dropdown-item>
                    <el-dropdown-item @click.native="del(item.roleId)">删除</el-dropdown-item>
                    <el-dropdown-item @click.native="toGrantAuthorizationPopup(item.roleId)">授权</el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown> -->
              </div>
              <div class="card-item">
                <div class="flex align_center pb10 mb10 br_bottom pl10 pr10">
                  <p class="item_seq">{{ getSeq(index, queryData.current, queryData.size) }}</p>
                  <p class="ml10 mr10">{{ item.roleName }}</p>
                  <p  class=" flex1 t_r" ></p>
                </div>
                <div class="pl10 pr10 pb10 mb10">
                  <p class="mb10">排序: {{ item.sort }}</p>
                  <p class="mb10">入口页: {{ item.indexUrl }}</p>
                  <p class="mb10">创建时间: {{ item.createTime | dateFormat1 }}</p>
                  <p class="mb10">备注: {{ item.remark }}</p>
                </div>
              </div>
            </div>
          </el-card>
        </div>

        <el-pagination
          class="floatRight"
          v-if="total > 10"
          @size-change="getDataListSizeChange"
          @current-change="getDataListCurrentChange"
          :current-page.sync="queryData.current"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="queryData.size"
          :total="total"
          layout="total,sizes, prev, pager, next"
        ></el-pagination>
      </el-card>
    </div>
    <add-or-edit ref="addOrEdit" @refreshDataList="getDataList()"></add-or-edit>
    <to-grant-authorization ref="toGrantAuthorization" @refreshDataList="getDataList()"></to-grant-authorization>
  </div>
</template>

<script>
import AddOrEdit from "./role-add-or-edit";
import toGrantAuthorization from "./role-to-grant-authorization";
export default {
  components: {
    AddOrEdit,
    toGrantAuthorization,
  },
  data() {
    return {
      loading: false,
      listData: [],
      total: 0,
      queryData: {
        size: 10,
        current: 1,
        like: [["String", "role_name", ""]],
        orderBy: [
          ["Number ", "sort", "false"],
        ],
      },
    };
  },
  created() {
    this.getDataList();
  },
  methods: {
    /**
     * 打开授权弹窗
     */
    toGrantAuthorizationPopup(id) {
      this.$nextTick(() => {
        this.$refs.toGrantAuthorization.init(id);
      });
    },
    /**
     * 新增或修改弹窗
     */
    addOrEditPopup(id) {
      this.$nextTick(() => {
        this.$refs.addOrEdit.init(id);
      });
    },
    /**
     * 删除数据
     */
    del(id) {
      if (id) {
        this.$confirm("是否删除所选择的数据?", "温馨提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            this.$http
              .delete(`admin/sys-role/baseDeleteById/${id}`)
              .then((res) => {
                if (res.data.code == 200) {
                  this.getDataList();
                  this.$message({
                    type: "success",
                    message: "删除成功!",
                  });
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "warning",
                  });
                }
              });
          })
          .catch(() => {
            this.$message({
              type: "info",
              message: "已取消删除",
            });
          });
      }
    },
    /**
     * 加载列表数据数据
     * @flag {boolean} 是否设置current为1
     */
    getDataList(flag) {
      if (flag) this.queryData.current = 1;
      this.loading = true;
      this.$http
        .post(`admin/sys-role/page`, this.$guolv(this.queryData))
        .then((res) => {
          if (res.data.code === 200) {
            let data = res.data.data;
            if (data) {
              this.listData = data.records;
              this.total = data.total;
            } else {
              this.listData = [];
              this.total = 0;
            }
            this.loading = false;
          }
        });
    },
    getDataListSizeChange(val) {
      this.queryData.size = val;
      this.getDataList(true);
    },
    getDataListCurrentChange(val) {
      this.queryData.current = val;
      this.getDataList();
    },
  },
};
</script>
<style scoped>
.templateClass {
  padding: 10px;
}

.pc-table {
  display: block;
}

.mobile-cards {
  display: none;
}

.role-card {
  margin-bottom: 20px;
}

.card-content {
  display: flex;
  flex-direction: column;
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.card-body {
  font-size: 14px;
  color: #666;
}

.el-card {
  border-radius: 8px;
  overflow: hidden;
}

.el-button {
  margin-left: 5px;
}

/* 媒体查询 */
@media (max-width: 768px) {
  .pc-table {
    display: none;
  }
  .mobile-cards {
    display: block;
  }
  .input-field {
    flex: 1 !important;
  }
}
</style>